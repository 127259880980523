<template>
  <nav id="main-nav">
      <ul>
          <li><router-link to="/menu">Menu</router-link></li>
          <li><router-link to="Account">Account</router-link></li>
          <li><router-link to="/order">Order</router-link></li>
      </ul>
  </nav>
</template>

<script>
export default {
  name: 'MainNav',
}
</script>

<style lang="scss" scoped>

@font-face {
    font-family: 'Nexa Slab';
    font-style: normal;
    font-weight: bold;
    src: url('/assets/fonts/Nexa-Slab-Bold.woff2') format('woff');
}
    
$font-stack-nexa-slab: 'Nexa Slab', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    

 #main-nav {

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0 0 .45em;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        display: flex;
        justify-content: center;
        
        li {
          flex-basis: 154px;
          padding: 0;
          margin: 0;
          display: block;
      
          color: #fff;
          font-family: 'Nexa Slab';
          font-weight: 700;

          a {
            display: block;
            min-width: 154px;
            margin: 0 16px 0 0;
            padding: 0 .2em;
            text-transform: uppercase;
            font-style: normal;
            line-height: 2.5;
            letter-spacing: 0.6px;
            text-align: center;
            color: #fff;
            text-decoration: none;
            &:visited {
              color: #fff;
            }
          }
        }
      }
      }
</style>
