<template>
  <div class="location-card">
      {{JSON.stringify(location)}}
        <div class="location-card-preview">
            <!-- <div class="location-card-image-container">
                <img [src]="location.primaryImageUrl" class="location-card-image">
            </div> -->
        </div>
        <div class="location-card-content">
            <div class="location-card-title">{{ location.displayName || location.name }}</div>
            <!-- <div class="location-card-distance">{{ location.distance | distance }}</div> -->
            <div class="location-card-address">{{ location.address }}</div>
            <div class="location-card-address">{{ location.city }}, {{ location.state }} {{ location.zip }}</div>
            <div class="location-card-button-container">
                <div>
                    <button v-if="location.isActive" v-on:click="selectLocation(location)">Order From Here</button>
                </div>
                <!-- <button :click="viewDetails(location)">More</button> -->
            </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'LocationCard',
  props: ['location','selectLocationMethod'],
  methods: {
      selectLocation: function(location) {
          this.selectLocationMethod(location);
      }
  }
}
</script>
