import Vue from "vue";
import Vuex from "vuex";
import Order from 'restaurant-online-ordering-client-library/dist/Order';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: {},
    currentUser: null,
    currentOrder: new Order(),
    currentLocation: null,
    menusByLocationId: new Map(),
  },

  getters: {
    errors(state) {
      return state.errors;
    },
    currentUser(state) {
      return state.currentUser;
    },
    currentOrder(state) {
      return state.currentOrder;
    },
    currentLocation(state) {
      return state.currentLocation;
    },
    getLocationMenuByLocationId: (state) => (locationId) => {
      return state.menusByLocationId.get(locationId);
    }
  },

  mutations: {
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_USER(state, currentUser) {
      console.log('SET_USER mutation', currentUser);
      state.currentUser = currentUser;
    },
    ADD_LINE_ITEM_TO_ORDER(state, newLineItem) {
      console.log('ADD_LINE_ITEM_TO_ORDER mutation', newLineItem);
      state.currentOrder.items.push(newLineItem);
    },
    // REMOVE_LINE_ITEM_FROM_ORDER(state, newLineItem) {
    //   console.log('ADD_LINE_ITEM_TO_ORDER mutation', newLineItem);
    //   state.currentOrder.items.push(newLineItem);
    // },
    SET_CURRENT_LOCATION(state, currentLocation) {
      state.currentLocation = currentLocation;
    },
    ADD_LOCATION_MENU(state, payload) {
      console.log('ADD_LOCATION_MENU mutation', payload);
      state.menusByLocationId.set(payload.locationId, payload.newMenu);
    },
  },

  actions: {
    setErrors({ commit }, errors) {
      commit("SET_ERRORS", errors);
    },
    setCurrentUser({ commit }, currentUser) {
      console.log('setCurrentUser action', currentUser);
      commit("SET_USER", currentUser);
    },
    addLineItemToOrder({ commit }, newLineItem) {
      console.log('addLineItemToOrder action', newLineItem);
      commit("ADD_LINE_ITEM_TO_ORDER", newLineItem);
    },
    setCurrentLocation({ commit }, currentLocation) {
      console.log('currentLocation action', currentLocation);
      commit("SET_CURRENT_LOCATION", currentLocation);
    },
    addLocationMenu({ commit }, payload) {
      console.log('addLocationMenu action', payload);
      commit("ADD_LOCATION_MENU", payload);
    }
  }
});