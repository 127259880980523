<template>
  <div id="app">
     <slot name="header">
       <a href="/">
         <img width="100" src="/assets/images/hbc_logo.svg">
       </a>
       Default logo and header content here.
     </slot>
    <router-view/>

     <slot name="footer">
       <main-nav></main-nav>
     </slot>
  </div>
</template>

<style lang="scss">
@import url('./assets/scss/test.scss');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import MainNav from '@/components/MainNav';
export default {
  name: 'App',
  components: {
    MainNav
  }
}
</script>