<template>
    <div>
        <menu-card :title="menuItem.itemAltName || menuItem.itemName" :imageUrl="menuItem.imagePath"></menu-card>    
    </div>
</template>
<script>
import MenuCard from './MenuCard';
import store from '@/store';
export default {
   name: 'MenuItemCard',
   props: ['menuItem'],
   methods: {
       addMenuItemToOrder: (menuItem) => {
            console.log('addMenuItemToOrder',menuItem);
            store.dispatch(
                'addLineItemToOrder',
                menuItem,
            )
       }
   },
   components: {
       MenuCard
    }
}
</script>

<style lang="scss" scoped>

</style>