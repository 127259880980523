<template>
  <section v-if="menu.length > 0" id="menu">
    <menu-category-card v-for="menuCategory in menu" :key="menuCategory.cloverId" :menu-category="menuCategory"></menu-category-card>
  </section>
</template>

<script>
import getLocationMenu from '@/services/restaurant-online-ordering/getLocationMenu';

//Menu Items
import MenuCategoryCard from '../components/MenuCategoryCard';

export default {
  components: { MenuCategoryCard },
  name: 'Menu',
  props: {
    locationId: String,
  },
  computed: {
    currentLocation() {
      return this.$store.state.currentLocation;
    }
  },
  data: function() { 
    return {
      menu: {},
    }
  },
  mounted: async function() {
    // Load specific location's menu if set in route
    // Otherwise, load/use state
    if (typeof this.locationId !== 'undefined') {
      const specificLocationMenu = await this.loadSpecificLocationMenu(this.locationId);
      console.log('loadSpecificLocationMenu', specificLocationMenu);
      this.addCurrentLocationMenu(this.locationId, specificLocationMenu);
    } else {
      if (!this.currentLocation) {
        this.$router.push('/locations');
      }
      const currentLocationMenu = await this.loadCurrentLocationMenu();
      console.log('currentLocationMenu', currentLocationMenu);

      this.addCurrentLocationMenu(this.currentLocation.mId, currentLocationMenu);
    }
  },
  methods: {
    loadCurrentLocationMenu: async function() {
      const locationId = this.currentLocation.mId;
      const menu = await getLocationMenu(locationId);
      this.menu = menu;
      return menu;
    },
    addCurrentLocationMenu: function(locationId, newMenu) {
      console.log('addCurrentLocationMenu method', locationId, newMenu)
      this.$store.dispatch(
            'addLocationMenu',
            {
              locationId,
              newMenu
            }
      );
    },
    loadSpecificLocationMenu: async function(locationId) {
      const menu = await getLocationMenu(locationId);
      this.menu = menu;
      return menu;
    },
  }
}
</script>
