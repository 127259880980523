<template>
  <div id="current-order">
      {{currentOrder.pickupType}}
      <div v-if="!!currentOrder && currentOrder.items.length > 0" class="current-order__items">
        <line-item-card v-for="item in currentOrder.items" :line-item="item" :key="item.cloverId"></line-item-card>
      </div>
  </div>
</template>

<script>
import store from '@/store';
import LineItemCard from '../components/LineItemCard.vue';

export default {
  name: 'CurrentOrder',
  computed: {
      currentOrder: () => {
          return store.state.currentOrder;
      }
  },
  components: {
    LineItemCard
    
  },
}
</script>
