<template>
    <article class="menu-card" :style="{'background-image': `url(${imageUrl})`}">
        <p class="menu-card__title">{{title}}</p>
        <div clas="menu-card__description">
            {{description}}
        </div>
    </article>    
</template>
<script>

export default {
   name: 'MenuCard',
   props: {
       title: String,
       imageUrl: String,
       description: String,
       link: String,
    },
   methods: {
    //    onMenuCardClick: (e) => {
    //        console.log('onMenuCardClick', e, this)
    //       this.$emit(this.onMenuCardClickEventToEmit);
        
    //    }
   }
}
</script>

<style lang="scss" scoped>

</style>