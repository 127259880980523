// TODO: Make env varibles for this.
const ROO_BASE_URL = 'https://api-staging.hotbirdieschicken.com/api/v1/';


export async function get(urlPath, params) {
    try {    
        // Default options are marked with *
        const response = await fetch(ROO_BASE_URL + urlPath, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit,
        params: params,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        return response.json(); // parses JSON response into native JavaScript objects
    } catch(error) {
        console.error(error);
        throw error;
    }
}


export async function post(urlPath, data, contentType = 'application/json') {
    try {    
        // Default options are marked with *
        const response = await fetch(ROO_BASE_URL + urlPath, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: (contentType == 'application/json') ? JSON.stringify(data) : response, // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    } catch(error) {
        console.error(error);
        throw error;
    }
}
