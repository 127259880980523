<template>
    <router-link :to="`/locations/${menuCategory.mId}/menu/categories/${menuCategory.cloverId}`">
        <menu-card :title="menuCategory.name" :imageUrl="menuCategory.imagePath"></menu-card>
    </router-link>
</template>
<script>
import MenuCard from './MenuCard.vue';
export default {
   name: 'MenuCategoryCard',
   props: ['menu-category'],
   components: { MenuCard }
}
</script>

<style lang="scss" scoped>

</style>