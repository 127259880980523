<template>
  <section v-if="!!menu.items && menu.items.length > 0">
    <menu-item-card v-for="menuItem in menu.items" :key="menuItem.cloverId" :menu-item="menuItem" v-on:click.native="selectMenuItem(menuItem)"></menu-item-card>

    <section v-if="!!selectedMenuItem">
      {{selectedMenuItem.modifierGroups}}
    </section>
  </section>
</template>

<script>

//Menu Items
import MenuItemCard from '../components/MenuItemCard';

export default {
  components: { MenuItemCard },
  name: 'CategoryMenu',
  props: {
    locationId: String,
    categoryId: String,
  },
  data: () => ({
    selectedMenuItem: null,
  }),
  computed: {
    menu() {
      const locationMenu = this.$store.getters.getLocationMenuByLocationId(this.locationId);
      if (locationMenu) {
        return locationMenu.find(category => category.cloverId == this.categoryId);
      } else {
        return {
          items: [],
        };
      }
    }
  },
  mounted: async function() {
    if (!this.$store.state.currentLocation) {
      this.$router.push('/locations');
    }
  },
  methods: {
    selectMenuItem(menuItem) {
      this.selectedMenuItem = menuItem;
    }
  }
}
</script>
