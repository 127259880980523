<template>
    <article class="line-item-card">
        <p class="line-item-card__name">{{lineItem.itemAltName || lineItem.name}}</p>
        <p class="line-item-card__price">{{lineItem.price | currency}}</p>

    </article>    
</template>
<script>
import '@/filters/currency';
export default {
   name: 'LineItemCard',
   props: {
       lineItem: Object
    },
}
</script>

<style lang="scss" scoped>

</style>