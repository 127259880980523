<template>
  <div class="user-login-form">
      <form @submit="attemptLogin">
          <input class="user-login-form__username" type="text" name="username" v-model="username" />
          <input class="user-login-form__password" type="password" name="password" v-model="password" />
            <button class="user-login-form__submit">Log in</button>
            <router-link to="/signup" class="user-login-form__sign-up">Sign Up</router-link>
            <router-link to="/forgotpassword" class="user-login-form__reset-password">Reset Password?</router-link>
      </form>
  </div>
</template>

<script>

import login from '@/services/restaurant-online-ordering/user/login';
// import store from '../store';

export default {
    name: 'LoginForm',
    data: function() { 
        return {
            'username': '',
            'password': '',
        }
    },
    methods: {
        async attemptLogin (e) {
            e.preventDefault();
            const currentUser = await login(this.username, this.password);
            console.log('currentUser is', currentUser);
            this.$store.dispatch(
                'setCurrentUser',
                currentUser
            );
            this.$router.push('/');
        }
    }
}
</script>
