import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginForm from '../components/LoginForm';

import CurrentOrder from '@/views/CurrentOrder.vue';
import Menu from '@/views/Menu.vue';
import CategoryMenu from '@/views/CategoryMenu.vue';

import Locations from '@/views/Locations.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
  },
  {
    path: '/locations/:locationId/menu',
    name: 'LocationMenu',
    component: Menu,
    props: route => ({ locationId: route.params.locationId }),
  },
  {
    path: '/order',
    name: 'CurrentOrder',
    component: CurrentOrder,
  },
  {
    path: '/locations/:locationId/menu/categories/:categoryId',
    name: 'CategoryMenu',
    component: CategoryMenu,
    props: route => ({ locationId: route.params.locationId, categoryId: route.params.categoryId }),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
