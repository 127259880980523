<template>
  <section id="locations">
      <LocationCard v-bind:key="location.mId" v-for="location in locations" :location="location" :selectLocationMethod="setCurrentLocation"></LocationCard>
  </section>
</template>

<script>
// @ is an alias to /src
import LocationCard from '@/components/LocationCard.vue'
import getAllLocations from '@/services/restaurant-online-ordering/getAllLocations';

export default {
  name: 'Locations',
  components: {
    LocationCard
  },
  data: () => {
      return {
          locations: [],
      }
  },
  mounted: async function() {
    console.log('mounted', this);
    await this.loadLocations();
  },
  methods: {
      loadLocations: async function() {
        this.locations = await getAllLocations();
      },
      setCurrentLocation: function(newCurrentLocation) {
        this.$store.dispatch(
            'setCurrentLocation',
            newCurrentLocation
        );

        // TODO: May redirect somewhere else, but for now
        this.$router.push(`/locations/${newCurrentLocation.mId}/menu`);
      }
  }
}
</script>
